import React from 'react'
import { graphql } from 'gatsby'
import { Newsletter as NewsletterTemplate } from '@templates'

const Newsletter = ({ data }) => <NewsletterTemplate data={data} />

export const query = graphql`
  query PrismicNewsletter {
    prismic {
      allNewsletter_pages {
        edges {
          node {
            seotitle
            seodescription
            title
            content
            mailinglist {
              ... on PRISMIC_Mailing_list {
                ...prismicMailingListFields
              }
            }
            banner {
              ... on PRISMIC_Banner {
                ...prismicBannerFields
              }
            }
          }
        }
      }
    }
  }
`


export default Newsletter
